import React, { useMemo } from 'react';
import { CashierExpressCheckoutWidget } from '@wix/cashier-express-checkout-widget';
import { OrderItem as ExpressOrderItem } from '@wix/cashier-express-checkout-widget/dist/src/types/OrderItem';
import {
  OnPaymentAuthorizedResult,
  PaymentAuthorizedArgs,
} from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import { Contact, Instance, DisplayableOrderItem, PriceComponents } from '@wix/restaurants-client-logic';
import dataHooks from '../../data-hooks';
import { convertCurrencyFromCents, filterNumberFromString } from '../../../../core/helpers/number.helper';
import styles from './CheckoutExpress.scss';

export interface CheckoutExpressProps {
  instance: Instance;
  signedInstance: string;
  language: string;
  currency: string;
  contact: Contact;
  displayableOrderItems: DisplayableOrderItem[];
  priceComponents: PriceComponents;
  hidden?: boolean;
  onPaymentAuthorized: (arg: PaymentAuthorizedArgs) => Promise<boolean>;
  onPaymentMethodChanged?: () => void;
}

const PAYMENT_LABEL = 'forApplePay';
const ITEM_CATEGORY = 'physical';
const PaymentMethods = {
  ApplePay: 'applePay',
};

const CheckoutExpress: React.FC<CheckoutExpressProps> = ({
  instance,
  signedInstance,
  language,
  displayableOrderItems,
  priceComponents,
  currency,
  hidden,
  onPaymentAuthorized,
  onPaymentMethodChanged,
}) => {
  const orderItems: ExpressOrderItem[] = useMemo(
    () =>
      displayableOrderItems.map((item) => {
        return {
          id: item.id,
          name: item.title,
          price: filterNumberFromString(item.price).toString(),
          quantity: item.quantity,
          category: ITEM_CATEGORY,
        };
      }),
    [displayableOrderItems],
  );

  const { appDefId, instanceId: appInstanceId, metaSiteId: siteId, aid: visitorId, siteOwnerId } = instance;
  const meta = {
    appDefId,
    appInstanceId,
    siteId,
    visitorId,
    siteOwnerId,
    appInstance: signedInstance,
  };

  const domain = window.location.hostname;
  const paymentAmount = convertCurrencyFromCents(priceComponents.total).toString();
  const style = hidden ? { display: 'none' } : undefined;

  const handlePaymentAuthorized = async (arg: PaymentAuthorizedArgs): Promise<OnPaymentAuthorizedResult> => {
    if (arg.error) {
      console.error('apple pay error onPaymentAuthorized', arg.error);
      return Promise.reject(new Error(arg.error.message));
    }
    const isSucceeded = await onPaymentAuthorized(arg);
    if (isSucceeded) {
      return Promise.resolve({ result: 'success' });
    } else {
      return Promise.reject(new Error());
    }
  }

  return (
    <div data-hook={dataHooks.expressCheckoutWidget} data-is-hidden={hidden} className={styles.wrapper} style={style}>
      <CashierExpressCheckoutWidget
        meta={meta}
        onCancel={window.location.reload}
        locale={language}
        paymentAmount={paymentAmount}
        paymentLabel={PAYMENT_LABEL}
        orderItems={orderItems}
        currency={currency}
        domain={domain}
        requestShipping={false}
        paymentBreakdown={{ itemsTotal: paymentAmount }}
        // @ts-ignore
        allowPaymentMethods={[PaymentMethods.ApplePay]}
        demoMode={false}
        onPaymentMethodSelectedInApplePay={(args) => {
          onPaymentMethodChanged?.();
          return Promise.resolve({ paymentAmount });
        }}
        onPaymentAuthorized={handlePaymentAuthorized}
      />
    </div>
  );
};

CheckoutExpress.displayName = 'CheckoutExpress';

export default CheckoutExpress;
